.MlsPlayerStatsWrapper{
    background-color: var(--third);
    padding: 16px;
    width: 100%;
}

.MlsLeagueData{
    max-width: 400px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.MlsLeagueDataContentFirst{
    display: flex;
    flex-direction: column;
    align-items: center;
}