.footerWrapper {
    width: 100%;
    background-color: var(--primary);
}


.footerWrapper .copyrightContainer {
    color: var(--secondary);
    text-align: center;
}

.footerWrapper .copyrightContainer p {
    margin: 0;
    padding: 8px 0px;
    font-size: 1vw;
}