.GamesWrapper{
    background-color: var(--third);
}
.GamesHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 8px 0px;
}

.GamesHeaderCard {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 24px;
    cursor: pointer;
    background-color: var(--fourth);
    opacity: 0.8;
    border-radius: 100px !important;
}
.GamesHeaderCard p,
.GamesHeaderCardSelected p {
    margin: 0;

}
.GamesHeaderCardSelected {
    border-radius: 100px;
    padding: 4px 24px;
    gap: 8px;
    display: flex;
    align-items: center;
    background-color: var(--secondary);
    color: var(--white);
    cursor: pointer;
}
.gamesScoreDate{
    background-color: var(--secondary);
    color: var(--fourth) !important;
    margin-bottom: 8px;
    padding: 4px;
    width: 100%;
    text-align: center;
    border-radius: 100px;
    white-space: nowrap;
}
.GamesContentContainer{
    display: flex;
    gap: 8px;
    overflow-x:auto;
}
.gamesContainerOverlay{
    background-color: var(--fourth);
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 24px;
}
.gamesContainerImage {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 50vh;
}

.gamesOverlay {
    border-radius: 16px;
    background: rgba(23,87,185, 0.9);
    width: 100%;
    height: 50vh;
    transition: .5s ease;
    opacity: 1;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.gamesCarouselItems {
    width: 100%;
    height: 50vh;
    background: rgba(23, 87, 185, 0.5);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5.8px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.GamesContentCardTicketLink{
    background-color: var(--third);
    text-decoration: none;
    text-align: center;
    padding: 8px 40px;
    border-radius: 8px;
}
.GamesContentCardTicketLink a{
    text-decoration: none;

}