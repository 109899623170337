.historiesContainer {
    height: 400px;
    width: 100%;
    overflow-y: scroll !important;
}

.historyTableHead {
    background-color: var(--primary) !important;
    color: var(--white) !important;
    white-space: nowrap;
}

.historiesContainer::-webkit-scrollbar {
    display: none;
}


@media screen and (max-width:992px) {
    .historyImageContainer {
        display: none;
    }

    .historyHeader {
        height: 20vh;
    }
}

.modalHeader {
    background: var(--third);
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100% !important;
}

.modalHeader h4 {
    color: var(--secondary) !important;
    font-weight: bold;
    margin: 0;
}