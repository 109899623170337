:root {
    --primary: #97fb57;
    --secondary: #121212;
    --third: #909090;
    --fourth: #f6f2f2;
    --black: #000;
    --gray: #3e3e3e;
    --white: #fff;
}

@font-face {
    font-family: Popins;
    src: url(./assets/fonts/Poppins-Regular.ttf);
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Popins';
    
}


body {
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    display: none;
}

p {
    margin: 0;
    padding: 0;
}