.NewsWrapper {
    padding: 0px !important;
    margin-bottom: 32px;
}

.newsContainer{
    margin-top: 24px;
    height: 80vh;
    overflow-y: scroll;
    padding: 0px 16px ;
}

.newsContentContainer{
    margin-bottom: 16px;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.newsContentContainer img{
    border-radius: 24px 24px 0px 0px !important;
}

.newsCategory{
    margin: 0;
    background-color: var(--primary);
    color: var(--secondary);
    display: inline-block;
    padding: 2px 16px;
    border-radius: 100px;
    text-transform: uppercase;
}
.newsPublished{
    margin: 0;
}

.readMore{
    background-color: var(--third);
    padding: 4px;
    text-align: center;
    border-radius: 100px;
}
.readMoreLink{
    color: var(--fourth);
    text-decoration: none;
}