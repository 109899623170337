.profileFieldsWrapper {
    padding: 32px 0px;
    width: 100%;
}

.inputLabel {
    color: var(--secondary);
    font-size: 16px;
    font-weight: bold;
}

.input,
.input:focus,
.inputD {
    height: 45px;
    border-radius: 100px !important;
}

.editSubmitButton {
    width: 100% !important;
    background-color: var(--primary) !important;
    border: none !important;
    padding: 8px !important;
    border-radius: 100px !important;
    font-weight: bold !important;
    color: var(--secondary) !important;
}

@media screen and (max-width:992px) {
    .ProfileImageContainer {
        display: none;
    }
}