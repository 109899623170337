.NbaDraftsContentContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
}
@media screen and (max-width:1100px) {
    .NbaDraftsContentContainer{
        height: 70vh;
        overflow-y: scroll;
    }
}
.NbaDraftsContent{
    background-color: var(--fourth);
    width: 200px;
    height: 220px;
    padding: 16px;
}

.nbaHead{
    border-radius: 100px;
}

.nbaLogo{
    background-color: var(--third);
    border-radius: 100px;
}

.nbaDraftsPickNo{
    background-color: var(--primary);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}