.title {
    margin-top: 8px;
    background-image: linear-gradient(#97fb57, #97fb57);
    background-size: 50% 10px;
    background-repeat: no-repeat;
    background-position: 0% 0%;
     transition: background-size .7s, background-position .5s ease-in-out;
  }
  
  .title:hover {
    background-size: 50% 100%;
    background-position: 0% 100%;
    transition: background-position .7s, background-size .5s ease-in-out;
  }