.PremierleagueGoalsContent{
    min-width: 350px;
    height: auto;
    background-color: var(--third);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.PremierleagueGoalsContent div h3{
    color: var(--);
}