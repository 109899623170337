.testimonialWrapper {
    padding: 100px 0px 32px 0px;
}

.testimonialContainer {
    display: flex;
    overflow-x: scroll;
    gap: 8px;
    padding: 0px 6px;
}

.testimonialContent {
    padding: 16px;
    background-color: var(--primary);
    /* border: 1px solid var(--secondary); */
    margin-bottom: 8px;
    border-radius: 24px;
    width: 300px;
    height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    flex-shrink: 0;
}

.testimonialContentName {
    white-space: nowrap;
}

.testimonialContentDescription{
    font-size: 14px;
    margin-top: 8px;
}
