.nbaRankingWrapper{
    margin-top: 24px;
    height: 80vh;
    overflow-y: scroll;
}

.nbaRankingGamesContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 8px;
}
.nbaRankingGames{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--third);
    padding: 4px 16px;
    border-radius: 100px;
}
.nbaRankingGamesRank{
    background-color: var(--primary);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}