.introductionImageCarousel{
    height: 80vh !important;
    width: 100%;
}
.introductionImageCarouselItem{
    height: 100% !important;
    width: 100%;
}
.introductionImage {
    height: 80vh;
    width: 100%;
}

@media screen and (max-width:800px) {
    .introductionImageWrapper {
        height: 70vh;
    }
}

@media screen and (max-width:500px) {
    .introductionImageWrapper {
        height: 50vh;
    }
}

@media screen and (max-width:350px) {
    .introductionImageWrapper {
        height: 40vh;
    }
}