.depositeWrapper {
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.depositeContainer {
    padding: 16px;
    width: 100%;
}

.depositeTitle {
    color: var(--secondary);
    font-weight: bold;
    margin-bottom: 24px;
}

.depositeAmountContainer p {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
}

.inputDisabled {
    height: 45px;
    width: 100%;
    font-size: 18px;
    padding-left: 16px;
    color: black;
    border: 1px solid #c4c4c4;
    border-radius: 100px;
}

.copyButton {
    margin-top: 16px !important;
    width: 100% !important;
    border-radius: 100px !important;
    border: none !important;
    background-color: var(--primary) !important;
    color: var(--white);
    font-weight: bold;
    padding-top: 12px !important;
    padding: 8px 0px;
}

@media screen and (max-width:992px) {
    .depositImageContainer {
        display: none;
    }
}