.contactButton,
.contactButton:hover,
.contactButton:focus {
    width: 100%;
    background-color: var(--primary) !important;
    color: var(--secondary) !important;
    font-weight: bold;
    border-radius: 100px !important;
    border: none !important;
}

.contactUsText {
    color: var(--secondary);
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
}