.usaLeaguesWrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 32px;
}

.usaLeagueContainer {
    width: 120px;
    background-color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 8px;
}

.usaLeagueImgContainer {
    padding: 8px !important;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usaLeagueContentContainer {
    padding: 8px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
}

.usaLeagueText {
    font-weight: bold;
    color: var(--primary);
    padding: 0px;
    margin: 0px;
}